import type { Utag } from '~/lib/tealium';

const JUBELMEDIA_PIXEL_ID = '849080396004553';

/**
 * FB Tracker with queue.
 * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
 * List of all Events: https://developers.facebook.com/docs/facebook-pixel/reference
 *
 * To use this FB event:
 * fbq('track', 'Purchase', {currency: "USD", value: 30.00})
 * call this:
 * trackFBEvent('Purchase', { currency: 'EUR', value: 0.00 });
 */

function hasWindow() {
  return typeof window !== 'undefined';
}

export type TrackingEvent = 'Purchase' | string;
export type TrackingPayload = Record<string, any> | null;

declare global {
  interface Window {
    utag?: Utag;
    wt?: any;
    wtm?: any;
    fbq?: (
      eventName: 'trackSingle' | 'trackCustom' | 'init' | 'track' | string,
      pixelId: string,
      event?: TrackingEvent,
      payload?: TrackingPayload
    ) => void;
  }
}

let fbTrackingTimeout: ReturnType<typeof setTimeout> | null = null;
let trackingQueue: { event: TrackingEvent; payload: TrackingPayload }[] = [];
let fbInitialized = false;

const consumeQueue = (iteration = 0) => {
  if (!fbInitialized) return; // wait until 'init' event was send
  if (fbTrackingTimeout) {
    clearTimeout(fbTrackingTimeout);
  }

  if (iteration > 10) return;

  const consentState = window.utag?.gdpr?.getConsentState?.();
  const isConsentSet = consentState && consentState > 0; // -1 = declined, 0 = pending, 1 = accepted
  const isConsentGiven = window.utag?.gdpr?.getCategories?.()?.includes('social');
  const isFbqAvailable = typeof window.fbq === 'function';
  // no consent given or fb not loaded, wait for 1s and try again.
  if (!isConsentSet || !isConsentGiven || !isFbqAvailable) {
    fbTrackingTimeout = setTimeout(() => {
      consumeQueue(iteration + 1);
    }, 1000);
    return;
  }

  trackingQueue.forEach(({ event, payload }) => {
    if (event === 'init') {
      window.fbq?.(event, JUBELMEDIA_PIXEL_ID);
      return;
    }

    window.fbq?.('trackSingle', JUBELMEDIA_PIXEL_ID, event, payload);
  });
  trackingQueue = [];
};

export const trackFBEvent = (event: TrackingEvent, payload: TrackingPayload = null) => {
  if (!hasWindow()) return;
  trackingQueue.push({
    event,
    payload,
  });
  consumeQueue();
};

export const initializeFBTracking = () => {
  // run initialize only once
  if (fbInitialized) return;
  fbInitialized = true;
  // fire init at first.
  trackingQueue.unshift({ event: 'init', payload: null }, { event: 'PageView', payload: null });
  consumeQueue();
};
