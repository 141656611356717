<template>
  <div class="layout">
    <PageHeader
      :theme="themeHeader"
      :hide-navi="hideNavi"
    />
    <client-only>
      <!-- Show popup only when nav is shown. -->
      <!-- <template v-if="themeHeader == null">
        <MagentaBlackDaysPopup />
      </template> -->
    </client-only>
    <nuxt class="layout__container" />
    <PageFooter :theme="themeFooter" />
    <DialogBackdrop />
    <client-only>
      <CustomerQueueDialog />
    </client-only>
    <portal-target name="globals" />
    <GlobalModal />
    <template v-if="isDev">
      <StatusToolbarWrapper />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, type AsyncComponent } from 'vue';
import { debounce } from 'lodash-es';
import { useRuntimeConfig, defineAsyncComponent, useRoute, useRouter, useNuxtApp } from '#imports';
import DialogBackdrop from '@/components/dialog-backdrop.vue';
import PageHeader from '@/components/page-header.vue';
import PageFooter from '@/components/page-footer.vue';
// import DefaultPopup from '@/components/default-popup.vue';
// import MagentaBlackDaysPopup from '@/components/magenta-black-days/magenta-black-days-popup.vue';
import CustomerQueueDialog from '@/components/customer-queue-dialog.vue';
import GlobalModal from '~/components/global-modal.vue';

const config = useRuntimeConfig();

const isDev = !config.public.mode.isProduction;

let StatusToolbarWrapper: AsyncComponent | null = null;

if (isDev) {
  StatusToolbarWrapper = defineAsyncComponent(
    () => import('@/components/status-toolbar-wrapper.vue')
  );
}

defineProps({
  themeHeader: { type: String, default: null },
  themeFooter: { type: String, default: 'standard' },
  showContent: { type: Boolean, default: true },
  hideNavi: { type: Boolean },
});

const hasWindow = () => typeof window !== 'undefined';

const store = useNuxtApp().$store;
const route = useRoute();
const router = useRouter();

const hasVerticalScrollbar = ref(false);

const maintenanceDowntime = computed(() => store.getters['downtimes/maintenanceDowntime']);

const isPageWithDowntime = computed(() =>
  [
    'aktionsangebote',
    'shop-warenkorb',
    'shop-kundenformular',
    'shop-zusammenfassung',
    'shop-list',
    'ShopList',
    'magenta-moments-2024',
    'ProductDetails',
    'magenta-tv-hardware',
    'preisvorteile-sichern',
  ].includes(route.name || '')
);

const isMaintenancePage = computed(() => route.name === 'maintenance');

function setViewportSize() {
  if (!hasWindow()) return;
  hasVerticalScrollbar.value = window.innerWidth > document.documentElement.clientWidth;
}

const resizeHandler = debounce(() => {
  setViewportSize();
}, 200);

async function checkForDowntime() {
  if (isMaintenancePage.value) return;
  if (!isPageWithDowntime.value) return;

  await store.dispatch('downtimes/fetchDowntimes');
}

async function initCart() {
  await store.dispatch('cart/initCart');
}

onMounted(async () => {
  if (!hasWindow()) return;

  setViewportSize();
  window.addEventListener('resize', resizeHandler);
  await checkForDowntime();

  if (!route.path?.startsWith('/shop/') && !maintenanceDowntime.value) {
    if (!config.public?.performanceMode?.enabled) {
      await initCart();
    }
  }
});

onUnmounted(() => {
  if (!hasWindow()) return;
  window.removeEventListener('resize', resizeHandler);
});

watch(
  () => route,
  async () => {
    await checkForDowntime();
  },
  {
    deep: true,
  }
);

watch(
  maintenanceDowntime,
  async (value) => {
    if (value && isPageWithDowntime.value && !isMaintenancePage.value) {
      await router.push('/maintenance');
      // Recheck required after routing.

      await checkForDowntime();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__container {
    flex: 1;
  }
}
</style>
