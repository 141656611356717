<template>
  <nuxt-link
    to="/merkliste"
    data-tealium-rel="header.button.merkliste"
    class="bookmark-indicator btn btn--transparent btn--borderless"
    active-class="bookmark-indicator--active"
    :aria-label="`Merkliste, ${
      productBookmarks?.length === 1 ? '1 Produkt' : `${productBookmarks?.length || 0} Produkte`
    }`"
  >
    <SvgIcon
      no-icon-class
      class="bookmark-indicator__icon"
      :class="iconClass"
      :image="bookmarkIcon"
    />
    <client-only>
      <CountBadge
        v-if="isBookmarkingAvailable"
        :value="productBookmarks.length"
        class="bookmark-indicator__count"
      />
    </client-only>
  </nuxt-link>
</template>

<script setup lang="ts">
import bookmarkIcon from '@/assets/images/icon-bookmark.svg';
import CountBadge from '@/components/count-badge.vue';
import useProductBookmarks from '@/composables/product-bookmarks';

defineProps({
  iconClass: {
    type: String,
    default: null,
  },
});

const { isBookmarkingAvailable, productBookmarks } = useProductBookmarks();
</script>

<style lang="scss" scoped>
@use 'assets/base' as *;

.bookmark-indicator {
  position: relative;

  &.btn--transparent {
    border-color: transparent;
  }

  &__count {
    font-size: 12px;
    position: absolute;
    right: -4px;
    top: 15px;
    background-color: var(--color-primary);
  }

  &__icon {
    fill: none;
    stroke: var(--color-font);
  }
}
</style>
