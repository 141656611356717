<template>
  <BaseLayout
    theme-header="small"
    theme-footer="small"
  >
    <slot v-if="cartInitialized" />
  </BaseLayout>
</template>

<script setup lang="ts">
import { watch, computed } from 'vue';
import { useRouter, useNuxtApp } from '#imports';
import BaseLayout from '~/components/base-layout.vue';

const store = useNuxtApp().$store;
const router = useRouter();

const cartIsEmpty = computed(() => store.getters['cart/cartIsEmpty']);
const cartInitialized = computed(() => store.getters['cart/cartInitialized']);

const isCartEmpty = computed(() => cartIsEmpty.value && cartInitialized.value);

watch(
  isCartEmpty,
  (value) => {
    if (value) {
      router.replace('/shop/warenkorb');
    }
  },
  { immediate: true }
);
</script>
