import { reactive, ref } from 'vue';
import type ModalType from '@i22-td-smarthome/component-library/dist/components/dialog/modal.vue';
import type { DynamicComponent, ModalButton } from '~/composables/modal-types';

export type ModalOpenProps = {
  heading?: string | null;
  className?: string | null;
  content?: string | null;
  component?: DynamicComponent | null;
  closeOnBackdrop?: boolean;
  buttons?: ModalButton[];
  noMaxWidth?: boolean;
  onCloseCallback?: () => void | undefined;
};

export type ModalPlugin = {
  open: (props: ModalOpenProps) => void;
  close: () => void;
  el: InstanceType<any>;
};

export const modalState = reactive<ModalOpenProps>({
  heading: null,
  content: null,
  component: null,
  className: null,
  buttons: [],
  closeOnBackdrop: true,
  noMaxWidth: false,
  onCloseCallback: undefined,
});

export const modal = ref<typeof ModalType | undefined>(undefined);

export const resetModal = () => {
  modalState.heading = null;
  modalState.content = null;
  modalState.component = null;
  modalState.className = null;
  modalState.buttons = [];
  modalState.noMaxWidth = false;
  modalState.closeOnBackdrop = true;
  modalState.onCloseCallback = undefined;
};

export const openModal = (props: ModalOpenProps) => {
  resetModal();
  modalState.buttons = props.buttons || [];
  Object.keys(props).forEach((key) => {
    // @ts-ignore
    modalState[key] = props[key];
  });

  modal.value?.openDialog();
};

export const closeModal = () => {
  if (modalState.onCloseCallback) {
    modalState.onCloseCallback();
  }
  modal.value?.closeDialog();
  resetModal();
};
