import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d233f890 = () => interopDefault(import('../pages/a-good-company.vue' /* webpackChunkName: "pages/a-good-company" */))
const _5da2117f = () => interopDefault(import('../pages/anker/index.vue' /* webpackChunkName: "pages/anker/index" */))
const _ffe4566a = () => interopDefault(import('../pages/bitte-kommen-sie-spaeter-wieder.vue' /* webpackChunkName: "pages/bitte-kommen-sie-spaeter-wieder" */))
const _17e9801b = () => interopDefault(import('../pages/google/index.vue' /* webpackChunkName: "pages/google/index" */))
const _35646408 = () => interopDefault(import('../pages/handyrechnung-aktion.vue' /* webpackChunkName: "pages/handyrechnung-aktion" */))
const _e35246f4 = () => interopDefault(import('../pages/handyvergleich.vue' /* webpackChunkName: "pages/handyvergleich" */))
const _e4126be2 = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _7799695e = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _3ed0cd78 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _bb65f172 = () => interopDefault(import('../pages/merkliste.vue' /* webpackChunkName: "pages/merkliste" */))
const _a0ff1390 = () => interopDefault(import('../pages/oster-angebote.vue' /* webpackChunkName: "pages/oster-angebote" */))
const _7690b383 = () => interopDefault(import('../pages/panzerglass.vue' /* webpackChunkName: "pages/panzerglass" */))
const _f6396362 = () => interopDefault(import('../pages/preisvorteile-sichern.vue' /* webpackChunkName: "pages/preisvorteile-sichern" */))
const _d4ab4518 = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _dca21192 = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _19776d24 = () => interopDefault(import('../pages/samsung/index.vue' /* webpackChunkName: "pages/samsung/index" */))
const _65979c2f = () => interopDefault(import('../pages/samsung-cashback-aktion.vue' /* webpackChunkName: "pages/samsung-cashback-aktion" */))
const _2d6bb0f2 = () => interopDefault(import('../pages/smart-home-entdecken.vue' /* webpackChunkName: "pages/smart-home-entdecken" */))
const _36abfe28 = () => interopDefault(import('../pages/stabiles-internet.vue' /* webpackChunkName: "pages/stabiles-internet" */))
const _78d92aba = () => interopDefault(import('../pages/themenwelten.vue' /* webpackChunkName: "pages/themenwelten" */))
const _6f72911a = () => interopDefault(import('../pages/xiaomi.vue' /* webpackChunkName: "pages/xiaomi" */))
const _0551131b = () => interopDefault(import('../pages/xiaomi-smartphones.vue' /* webpackChunkName: "pages/xiaomi-smartphones" */))
const _3c7cc096 = () => interopDefault(import('../pages/xiaomi-zubehoer.vue' /* webpackChunkName: "pages/xiaomi-zubehoer" */))
const _e7fae3ce = () => interopDefault(import('../pages/zahlung-per-handyrechnung.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung" */))
const _76f0e08c = () => interopDefault(import('../pages/anker/product-data.ts' /* webpackChunkName: "pages/anker/product-data" */))
const _d15ecfc0 = () => interopDefault(import('../pages/google/product-data.js' /* webpackChunkName: "pages/google/product-data" */))
const _0792550c = () => interopDefault(import('../pages/hilfe/lieferstatus-und-retoure.vue' /* webpackChunkName: "pages/hilfe/lieferstatus-und-retoure" */))
const _73b349a4 = () => interopDefault(import('../pages/magenta-black-days/page-data.js' /* webpackChunkName: "pages/magenta-black-days/page-data" */))
const _1b544f2e = () => interopDefault(import('../pages/samsung/product-data.ts' /* webpackChunkName: "pages/samsung/product-data" */))
const _0b7631e4 = () => interopDefault(import('../pages/shop/bestaetigung.vue' /* webpackChunkName: "pages/shop/bestaetigung" */))
const _02e41a02 = () => interopDefault(import('../pages/shop/brodos-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/brodos-zahlungsverifizierung" */))
const _4607e34c = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _79e853ff = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _5380c17c = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _65631514 = () => interopDefault(import('../pages/shop/kundenformular/persoenliche-daten.vue' /* webpackChunkName: "pages/shop/kundenformular/persoenliche-daten" */))
const _749f63ba = () => interopDefault(import('../pages/shop/kundenformular/zahlungsart.vue' /* webpackChunkName: "pages/shop/kundenformular/zahlungsart" */))
const _aceeed08 = () => interopDefault(import('../pages/shop/telekom-bestaetigung.vue' /* webpackChunkName: "pages/shop/telekom-bestaetigung" */))
const _1941e437 = () => interopDefault(import('../pages/shop/telekom-bezahlung-information.vue' /* webpackChunkName: "pages/shop/telekom-bezahlung-information" */))
const _c8a76c1e = () => interopDefault(import('../pages/shop/telekom-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/telekom-zahlungsverifizierung" */))
const _4ebb8eaa = () => interopDefault(import('../pages/shop/warenkorb.vue' /* webpackChunkName: "pages/shop/warenkorb" */))
const _5e6b109f = () => interopDefault(import('../pages/shop/zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/zahlungsverifizierung" */))
const _1423f267 = () => interopDefault(import('../pages/shop/zusammenfassung.vue' /* webpackChunkName: "pages/shop/zusammenfassung" */))
const _430bcbbe = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _ebd66144 = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _b95e7104 = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _feb4da1c = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _8056437a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d3994d8 = () => interopDefault(import('../pages/magenta-moments-2024/_slug.vue' /* webpackChunkName: "pages/magenta-moments-2024/_slug" */))
const _f4e4b032 = () => interopDefault(import('../special-pages/product-details.vue' /* webpackChunkName: "" */))
const _ffccdd50 = () => interopDefault(import('../special-pages/shop-list.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-good-company",
    component: _d233f890,
    name: "a-good-company"
  }, {
    path: "/anker",
    component: _5da2117f,
    name: "anker"
  }, {
    path: "/bitte-kommen-sie-spaeter-wieder",
    component: _ffe4566a,
    name: "bitte-kommen-sie-spaeter-wieder"
  }, {
    path: "/google",
    component: _17e9801b,
    name: "google"
  }, {
    path: "/handyrechnung-aktion",
    component: _35646408,
    name: "handyrechnung-aktion"
  }, {
    path: "/handyvergleich",
    component: _e35246f4,
    name: "handyvergleich"
  }, {
    path: "/infos-zum-online-kauf",
    component: _e4126be2,
    name: "infos-zum-online-kauf"
  }, {
    path: "/kontakt",
    component: _7799695e,
    name: "kontakt"
  }, {
    path: "/maintenance",
    component: _3ed0cd78,
    name: "maintenance"
  }, {
    path: "/merkliste",
    component: _bb65f172,
    name: "merkliste"
  }, {
    path: "/oster-angebote",
    component: _a0ff1390,
    name: "oster-angebote"
  }, {
    path: "/panzerglass",
    component: _7690b383,
    name: "panzerglass"
  }, {
    path: "/preisvorteile-sichern",
    component: _f6396362,
    name: "preisvorteile-sichern"
  }, {
    path: "/ratenzahlung",
    component: _d4ab4518,
    name: "ratenzahlung"
  }, {
    path: "/redirecting",
    component: _dca21192,
    name: "redirecting"
  }, {
    path: "/samsung",
    component: _19776d24,
    name: "samsung"
  }, {
    path: "/samsung-cashback-aktion",
    component: _65979c2f,
    name: "samsung-cashback-aktion"
  }, {
    path: "/smart-home-entdecken",
    component: _2d6bb0f2,
    name: "smart-home-entdecken"
  }, {
    path: "/stabiles-internet",
    component: _36abfe28,
    name: "stabiles-internet"
  }, {
    path: "/themenwelten",
    component: _78d92aba,
    name: "themenwelten"
  }, {
    path: "/xiaomi",
    component: _6f72911a,
    name: "xiaomi"
  }, {
    path: "/xiaomi-smartphones",
    component: _0551131b,
    name: "xiaomi-smartphones"
  }, {
    path: "/xiaomi-zubehoer",
    component: _3c7cc096,
    name: "xiaomi-zubehoer"
  }, {
    path: "/zahlung-per-handyrechnung",
    component: _e7fae3ce,
    name: "zahlung-per-handyrechnung"
  }, {
    path: "/anker/product-data",
    component: _76f0e08c,
    name: "anker-product-data"
  }, {
    path: "/google/product-data",
    component: _d15ecfc0,
    name: "google-product-data"
  }, {
    path: "/hilfe/lieferstatus-und-retoure",
    component: _0792550c,
    name: "hilfe-lieferstatus-und-retoure"
  }, {
    path: "/magenta-black-days/page-data",
    component: _73b349a4,
    name: "magenta-black-days-page-data"
  }, {
    path: "/samsung/product-data",
    component: _1b544f2e,
    name: "samsung-product-data"
  }, {
    path: "/shop/bestaetigung",
    component: _0b7631e4,
    name: "shop-bestaetigung"
  }, {
    path: "/shop/brodos-zahlungsverifizierung",
    component: _02e41a02,
    name: "shop-brodos-zahlungsverifizierung"
  }, {
    path: "/shop/fehler",
    component: _4607e34c,
    name: "shop-fehler"
  }, {
    path: "/shop/kundenformular",
    component: _79e853ff,
    children: [{
      path: "",
      component: _5380c17c,
      name: "shop-kundenformular"
    }, {
      path: "persoenliche-daten",
      component: _65631514,
      name: "shop-kundenformular-persoenliche-daten"
    }, {
      path: "zahlungsart",
      component: _749f63ba,
      name: "shop-kundenformular-zahlungsart"
    }]
  }, {
    path: "/shop/telekom-bestaetigung",
    component: _aceeed08,
    name: "shop-telekom-bestaetigung"
  }, {
    path: "/shop/telekom-bezahlung-information",
    component: _1941e437,
    name: "shop-telekom-bezahlung-information"
  }, {
    path: "/shop/telekom-zahlungsverifizierung",
    component: _c8a76c1e,
    name: "shop-telekom-zahlungsverifizierung"
  }, {
    path: "/shop/warenkorb",
    component: _4ebb8eaa,
    name: "shop-warenkorb"
  }, {
    path: "/shop/zahlungsverifizierung",
    component: _5e6b109f,
    name: "shop-zahlungsverifizierung"
  }, {
    path: "/shop/zusammenfassung",
    component: _1423f267,
    name: "shop-zusammenfassung"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _430bcbbe,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _ebd66144,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _b95e7104,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _feb4da1c,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/",
    component: _8056437a,
    name: "index"
  }, {
    path: "/magenta-moments-2024/:slug?",
    component: _6d3994d8,
    name: "magenta-moments-2024-slug"
  }, {
    path: "/geraete/:slug?",
    component: _f4e4b032,
    name: "ProductDetails"
  }, {
    path: "/:config+",
    component: _ffccdd50,
    name: "ShopList"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
